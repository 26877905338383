export const dev_config = {
    authDomain: "https://grihadevauth.paloalto-soft.com",
    clientId: "orOuBgSYFx45znJxfC3xgTWjaVpXSFoD",
    apiDomain: "https://wivqam17l7.execute-api.ap-south-1.amazonaws.com/dev",
    version: "v1",
    app: "svagriha",
    app_id: "645b8b55439cacaf9171ca46",
    svagriha_url:"https://dev2svagriha.paloalto-soft.com",
    schoolgriha_url:'https://schoolgrihadev.paloalto-soft.com',
    griha_url:"https://dev2griha.paloalto-soft.com",
    jangriha_url:"https://jangrihain.paloalto-soft.com",
    old_griha_base: 'https://dev2griha.paloalto-soft.com',
    backRedirection: {
        admin: '',
        client: '',
        coordinator: '',
    },
    audience: "https://dev-5onb5ctiywl2tjy2.us.auth0.com/api/v2/",
    pushNotiPublicKey:'BJc2npwt2r_JLASdHTWnRhC5-yeOM5grtpZx9fe6qAliv7PYz8xyE22wZecbdpSexJhPhNsZmASvWnwc97p6F3Y'
}