import { Component, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.scss']
})
export class BarGraphComponent {
  
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;

  @Input() xaxisCategories: string[] = [];
  @Input() data: number[] = [];
  @Input() title: string = '';
  @Input() colors: string[] = ['#69b3a2', '#ff4560', '#008ffb', '#feb019'];

  private margin = { top: 40, right: 20, bottom: 50, left: 50 };
  private width = 700 - this.margin.left - this.margin.right;
  private height = 400 - this.margin.top - this.margin.bottom;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.createChart();
  }

  private createChart(): void {
    const element = this.chartContainer.nativeElement;

    // Clear previous SVG
    d3.select(element).select('svg').remove();

    // Get the container's width dynamically
    const containerWidth = element.offsetWidth;
    const containerHeight = 400;

    const margin = { top: 40, right: 20, bottom: 50, left: 50 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    const svg = d3
      .select(element)
      .append('svg')
      .attr('viewBox', `0 0 ${containerWidth} ${containerHeight}`)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Scales
    const x = d3
      .scaleBand()
      .domain(this.xaxisCategories)
      .range([0, width])
      .padding(0.2);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(this.data) || 0])
      .nice()
      .range([height, 0]);

    // Axes
    svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .style('text-anchor', 'end');

    svg.append('g').call(d3.axisLeft(y));
    
    // Title
    svg
      .append('text')
      .attr('x', width / 2)
      .attr('y', -25) // Adjust position to move the title upwards
      .attr('text-anchor', 'middle')
      .style('font-size', '16px')
      .text(this.title);

    const isAllZero = this.data.every((value) => value === 0);

    if (!isAllZero) {
      // Bars
      svg
        .selectAll('.bar')
        .data(this.data)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', (_, i) => x(this.xaxisCategories[i])!)
        .attr('y', (d) => y(d))
        .attr('width', x.bandwidth())
        .attr('height', (d) => height - y(d))
        .attr('fill', (_, i) => this.colors[i % this.colors.length]);


      svg
        .selectAll('.label')
        .data(this.data)
        .enter()
        .append('text')
        .attr('class', 'label')
        .attr('x', (_, i) => x(this.xaxisCategories[i])! + x.bandwidth() / 2) // Center text in the bar
        .attr('y', (d) => y(d) - 5) // Increase spacing above the bar
        .attr('text-anchor', 'middle')
        .text((d) => d) // Add the value as text
        .style('fill', 'black') // Optional: Change text color
        .style('font-size', '12px'); // Optional: Adjust font size
    } else {
      svg
        .append('text')
        .attr('x', width / 2)
        .attr('y', height / 2)
        .attr('text-anchor', 'middle')
        .style('font-size', '14px')
        .style('fill', 'gray')
        .text('No data available to display');
    }
  }
}
