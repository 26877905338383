import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-donut-bar',
  templateUrl: './donut-bar.component.html',
  styleUrl: './donut-bar.component.scss',
})
export class DonutBarComponent {
  @ViewChild('donutContainer', { static: true }) chartContainer!: ElementRef;

  @Input() xaxisCategories: string[] = [];
  @Input() data: number[] = [];
  @Input() title: string = '';
  @Input() colors: string[] = ['#69b3a2', '#ff4560', '#008ffb', '#feb019'];
  @Input() labels: string[] = ['A', 'B', 'C', 'D'];

  // private margin = { top: 40, right: 20, bottom: 50, left: 50 };
  // private width = 700 - this.margin.left - this.margin.right;
  // private height = 400 - this.margin.top - this.margin.bottom;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.createChart();
  }

  private createChart(): void {
    const element = this.chartContainer.nativeElement;

    // Clear any existing content
    d3.select(element).html('');

    // Add the title
    const containerWidth = element.offsetWidth;
    const containerHeight = 400;

    const svg = d3
      .select(element)
      .append('svg')
      .attr('width', containerWidth)
      .attr('height', containerHeight);

    svg
      .append('text')
      .attr('x', containerWidth / 2)
      .attr('y', 20)
      .attr('text-anchor', 'middle')
      .style('font-size', '16px')
      .style('font-weight', 'bold')
      .text(this.title);

    // Filter out invalid (NaN) data
    const validData = this.data.map((value) => (isNaN(value) ? 0 : value));

    const total = d3.sum(validData);

    if (total === 0) {
      svg
        .append('text')
        .attr('x', containerWidth / 2)
        .attr('y', containerHeight / 2)
        .attr('text-anchor', 'middle')
        .style('font-size', '14px')
        .style('fill', '#888')
        .text('No valid data available to display the chart.');
      return;
    }

    const adjustedData = [...validData];

    if (total > 100) {
      const overflow = total - 100;
      adjustedData.push(-overflow); // Add negative segment
      this.labels.push('Overflow'); // Label for the overflow
      this.colors.push('#888'); // Color for the overflow
    }

    // Proceed with chart rendering
    const margin = { top: 40, right: 60, bottom: 60, left: 60 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;
    const radius = Math.min(width, height) / 2;

    const chartGroup = svg
      .append('g')
      .attr(
        'transform',
        `translate(${containerWidth / 2}, ${containerHeight / 2})`
      );

    // Create the pie generator
    const pie = d3
      .pie<number>()
      .value((d) => d)
      .sort(null)
      .startAngle(Math.PI / 2) // Start from right (90 degrees in radians)
      .endAngle(2.5 * Math.PI); // Full circle (360 degrees, offset by 90 degrees)

    // Create the arc generator
    const arc = d3
      .arc<d3.PieArcDatum<number>>()
      .innerRadius(radius * 0.6) // Donut chart with inner radius
      .outerRadius(radius);

    // Create the color scale
    const color = d3.scaleOrdinal<number, string>(this.colors);

    // Bind data to the pie chart
    const arcs = chartGroup
      .selectAll('arc')
      .data(pie(validData))
      .enter()
      .append('g')
      .attr('class', 'arc');

    // Draw paths
    arcs
      .append('path')
      .attr('d', arc)
      .attr('fill', (_, i) => color(i))
      .style('opacity', (d) => (d.data < 0 ? 0.5 : 1)); // Make overflow segment semi-transparent

    // Add labels
    // arcs
    //   .append('text')
    //   .attr('transform', (d) => `translate(${arc.centroid(d)})`)
    //   .attr('text-anchor', 'middle')
    //   .style('font-size', '12px')
    //   .text((d, i) => this.labels[i]);

    // Add percentage labels
    arcs
      .append('text')
      .attr(
        'transform',
        (d) => `translate(${arc.centroid(d).map((v) => v * 1.5)})`
      )
      .attr('text-anchor', 'middle')
      .style('font-size', '10px')
      .style('fill', '#555')
      .text((d) =>
        d.data > 0
          ? `${((d.data / total) * 100).toFixed(1)}%`
          : `-${Math.abs(+((d.data / total) * 100).toFixed(1))}%`
      );
  }
}
