import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {catchError, Observable, throwError} from 'rxjs';
import {ProjectData} from '../../model/ProjectData';
import {ResponsePacket} from '../../model/ResponsePacket';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private PROJECT_BY_ID_URL = '/project?project_code=#';
  private Project_URL = '/project';
  private AUDIT_LOG_URL = `/audit/?project=645b508f7136378077ade7f0`;
  private Fee_Detail_URL = `/fee/?_id=64882ec3b4ceed6ad19c48c6`;
  private Fee_Download_URL = `/fee/645b506b7136378077ade7eb`;
  private PROJECT_BY_ID='/project/#';
  private CITY_URL='/app/city'
  private STATE_URL='/state';
  private Site_Visit_Report = '/sitevisitreport/#';
  private Site_Visit_Report_PDF =
    '/sitevisitreportappraisal/getsitevisitreportpdf/#';
  private CRITERIA_CONFIG_URL='/app/criteriaconfig'
  private Site_Visit_Report_Scheduling = '/sitevisitscheduling';
  private DOWNLOAD_CERTIFICATE_URL="/project/certificate/#";
  private activityLogs = '/audit/#'
  private SUB_PROJECT_URL = '/project/subprojects/#';
  private get_impact_url = '/impact/getCriteriaCases/#';
  private impact_url = '/impact/#';

  constructor(private httpClient:HttpClient) {
  }

 public getAudit(projectCode:any, userName:any){
  let listSize = 10;
  let page = 1
  const apiUrl = `${this.activityLogs.replace('#', projectCode)}?username=${userName}&list_size=${listSize}&page=${page}`;
  return this.httpClient.get(apiUrl)
 }

  public getProject = (id: string): Observable<ResponsePacket<ProjectData[]>> => {
    return this.httpClient.get<ResponsePacket<ProjectData[]>>(this.PROJECT_BY_ID_URL.replace('#', id))
      .pipe(
        catchError(this.handleError)
      )
  }

  public getSubProject(id:string,params:any){
    return this.httpClient.get(this.SUB_PROJECT_URL.replace('#',id),{params:params});
  }

  public getSiteVisitReportId(project_id: any, scheduleId: string) {
    return this.httpClient.get(
      this.Site_Visit_Report.replace('#', project_id) +
        `?site_visit_scheduling=${scheduleId}`
    );
  }

  downloadCertificate=(project_code:string):Observable<any>=>{
    return this.httpClient.get(this.DOWNLOAD_CERTIFICATE_URL.replace('#',project_code)).pipe(
      catchError(this.handleError)
    );
  }

  downloadFile(url: string): Observable<any> {
    // Set headers if needed, e.g., for authorization
    const headers = new HttpHeaders();

    // Make the HTTP GET request to the URL
    return this.httpClient.get(url, {
      headers: headers,
      responseType: 'blob' as 'json' // Specify the response type as 'blob'
    });
  }

  public getSiteVisitReportSchedule(projectId: string) {
    return this.httpClient.get(
      this.Site_Visit_Report_Scheduling + `?project=${projectId}`
    );
  }

  getSiteVisitReportPdf(
    projectId: string,
    siteVisitReportId: string
  ): Observable<Blob> {
    const apiUrl = `${this.Site_Visit_Report_PDF.replace(
      '#',
      projectId
    )}?site_visit_report=${siteVisitReportId}`;
    return this.httpClient.get(apiUrl, { responseType: 'blob' });
  }

  public getAuditLog(){
    return this.httpClient.get(this.AUDIT_LOG_URL)
  }

  public getCity(){
    return this.httpClient.get(this.CITY_URL)
  }

  public getStates() {
    return this.httpClient.get(this.STATE_URL);
  }

  public getFeeDetail(){
    return this.httpClient.get(this.Fee_Detail_URL)
  }

  public exportFeeDownload(){
    return this.httpClient.get(this.Fee_Download_URL)
  }

  public getSave(body: any, id: any){
    return this.httpClient.patch(this.Project_URL+'/'+id, body)
  }

  public getCriteriaConfig(){
    return this.httpClient.get(this.CRITERIA_CONFIG_URL);
  }

  public getImpactData(id:string){
    return this.httpClient.get(this.get_impact_url.replace('#',id))
  }

  public uploadImpactData(id:string,body:any){
    return this.httpClient.post(this.impact_url.replace('#',id),body);
  }

  public updateImpactData(id:string,body:any){
    return this.httpClient.patch(this.impact_url.replace('#',id),body);
  }

  private handleError = (error: any) => {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  public updateProject(project_code:string,body:any){
    return this.httpClient.patch(this.PROJECT_BY_ID.replace('#',project_code),body)
  }
}
