import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-semi-circle',
  templateUrl: './semi-circle.component.html',
  styleUrl: './semi-circle.component.scss',
})
export class SemiCircleComponent {
  @ViewChild('semiCircleContainer', { static: true })
  chartContainer!: ElementRef;

  @Input() xaxisCategories: string[] = [];
  @Input() data: number[] = [];
  @Input() title: string = '';
  @Input() colors: string[] = ['#69b3a2', '#ff4560', '#008ffb', '#feb019'];
  @Input() labels: string[] = ['A', 'B', 'C', 'D'];

  // private width = 300;
  // private height = 190; // Half the height for a half-circle
  private radius = 150;

  ngAfterViewInit(): void {
    // this.createGauge();
    this.handleResize();
    window.addEventListener('resize', () => this.handleResize());
  }

  private handleResize(): void {
    const currentScreenWidth = window.innerWidth;
    console.log('change screen size ',currentScreenWidth)
    // Recreate the SVG chart with updated dimensions
    this.recreateGauge(currentScreenWidth);
  }

  private recreateGauge(screenWidth: number): void {
    // Clear the existing chart content
    d3.select(this.chartContainer.nativeElement).html('');
  
    // Adjust container height or other properties if needed
    const containerHeight = screenWidth <= 1300 ? 220 : 260;
  
    this.createGauge(containerHeight);
  }

  private createGauge(containerHeight: number = 260): void {
    const element = this.chartContainer.nativeElement;

    // Clear any existing content
    d3.select(element).html('');

    // Add the title
    const containerWidth = element.offsetWidth;
    // let containerHeight = 280;

    const svg = d3
      .select(element)
      .append('svg')
      .attr('width', containerWidth)
      .attr('height', containerHeight);

    svg
      .append('text')
      .attr('x', containerWidth / 2)
      .attr('y', 20)
      .attr('text-anchor', 'middle')
      .style('font-size', window.innerWidth <= 1300 ? '14px' : '16px')
      .style('font-weight', 'bold')
      .text(`Criterion ${this.title} Strategy Impact on GWP KgCO₂`);

    // Filter out invalid (NaN) data
    const validData = this.data.map((value) => (isNaN(value) ? 0 : value));

    const total = d3.sum(validData);

    if (total === 0) {
      svg
        .append('text')
        .attr('x', containerWidth / 2)
        .attr('y', containerHeight / 2)
        .attr('text-anchor', 'middle')
        .style('font-size', window.innerWidth <= 1300 ? '12px' : '14px')
        .style('fill', '#888')
        .text('No valid data available to display the chart.');
      return;
    }

    const adjustedData = [...validData];

    if (total > 100) {
      const overflow = total - 100;
      adjustedData.push(-overflow); // Add negative segment
      this.labels.push('Overflow'); // Label for the overflow
      this.colors.push('#888'); // Color for the overflow
    }

    // Proceed with chart rendering
    const margin = { top: 40, right: 40, bottom: 40, left: 40 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;
    const radius = (width - 20) / 2;

    const chartGroup = svg
      .append('g')
      .attr(
        'transform',
        `translate(${containerWidth / 2}, ${radius + 50})`
      );

    // Create the pie generator
    const pie = d3
      .pie<number>()
      .value((d) => d)
      .sort(null)
      .startAngle(-Math.PI / 2) // Start from right (90 degrees in radians)
      .endAngle(Math.PI / 2); // Full circle (360 degrees, offset by 90 degrees)

    // Create the arc generator
    const arc = d3
      .arc<d3.PieArcDatum<number>>()
      .innerRadius(radius * 0.6) // Donut chart with inner radius
      .outerRadius(radius);

    // Create the color scale
    const color = d3.scaleOrdinal<number, string>(this.colors);

    // Bind data to the pie chart
    const arcs = chartGroup
      .selectAll('arc')
      .data(pie(validData))
      .enter()
      .append('g')
      .attr('class', 'arc');

    // Draw paths
    arcs
      .append('path')
      .attr('d', arc)
      .attr('fill', (_, i) => color(i))
      .style('opacity', (d) => (d.data < 0 ? 0.5 : 1)); // Make overflow segment semi-transparent

    // Add percentage labels
    arcs
      .append('text')
      .attr(
        'transform',
        (d) => `translate(${arc.centroid(d)})`
      )
      .attr('text-anchor', 'middle')
      .style('font-size', window.innerWidth <= 1300 ? '12px' : '14px')
      .style('fill', 'black')
      .text((d) =>
        d.data > 0
          ? `${((d.data / total) * 100).toFixed(1)}%`
          : `-${Math.abs(+((d.data / total) * 100).toFixed(1))}%`
      );

    // Add 0% label on the left side (180 degrees)
    chartGroup
      .append('text')
      .attr('x', -(radius + 20)) // Position at left side
      .attr('y', 0)
      .attr('text-anchor', 'middle')
      .style('font-size', window.innerWidth <= 1300 ? '12px' : '14px')
      .style('fill', 'black')
      .text('0%');

    // Add 100% label on the right side (0 degrees)
    chartGroup
      .append('text')
      .attr('x', radius + 20) // Position at right side
      .attr('y', 0)
      .attr('text-anchor', 'middle')
      .style('font-size', window.innerWidth <= 1300 ? '12px' : '14px')
      .style('fill', 'black')
      .text('100%');

    const needleAngle = (this.data[0] / 100) * Math.PI - Math.PI / 2;
    const needleLength = this.radius - 40;
    const needleWidth = 6;

    const needlePath = [
      [-needleWidth / 2, 0],
      [0, -needleLength],
      [needleWidth / 2, 0],
    ]
      .map(([x, y]) => {
        const cos = Math.cos(needleAngle);
        const sin = Math.sin(needleAngle);
        return [x * cos - y * sin, x * sin + y * cos];
      })
      .map(([x, y]) => `${x},${y}`)
      .join(' ');

    // Append needle to the chart
    chartGroup
      .append('polygon')
      .attr('points', needlePath)
      .attr('fill', 'black');

    // Add center circle (pivot point of the needle)
    chartGroup.append('circle').attr('r', 5).attr('fill', 'black');
  }
}
